import { request } from '@/utils/request'

export function historyOther(params?: any, data?: any) {
  return request({ url: '/video/historyOther', method: 'get', params, data })
}

export function historyVideo(params?: any, data?: any) {
  return request({ url: '/video/history', method: 'get', params, data })
}

export function recommendedVideo(params?: any, data?: any) {
  return request({ url: '/video/recommended', method: 'get', params, data })
}

export function recommendedLongVideo(params?: any, data?: any) {
  return request({ url: '/video/long_recommended', method: 'get', params, data })
}

export function myVideo(params?: any, data?: any) {
  return request({ url: '/video/my', method: 'get', params, data })
}

export function privateVideo(params?: any, data?: any) {
  return request({ url: '/video/private', method: 'get', params, data })
}

export function likeVideo(params?: any, data?: any) {
  return request({ url: '/video/like', method: 'get', params, data })
}

export function videoComments(params?: any, data?: any) {
  return request({ url: '/video/comments', method: 'get', params, data })
}

export function firstTag(params?: any, data?: any) {
  return request({ url: '/video/first_tag', method: 'get', params, data })
}
export function secondTag(params?: any, data?: any) {
  return request({ url: '/video/second_tag', method: 'get', params, data })
}
export function thirdTag(params?: any, data?: any) {
  return request({ url: '/video/third_tag', method: 'get', params, data })
}
export function fourthTag(params?: any, data?: any) {
  return request({ url: '/video/fourth_tag', method: 'get', params, data })
}

export function newestVideo(params?: any, data?: any) {
  return request({ url: '/video/newest_video', method: 'get', params, data })
}

export function hotestVideo(params?: any, data?: any) {
  return request({ url: '/video/hotest_video', method: 'get', params, data })
}

export function newestCommentVideo(params?: any, data?: any) {
  return request({ url: '/video/newest_comment_video', method: 'get', params, data })
}

export function canPay(params?: any, data?: any) {
  return request({ url: '/video/can_pay', method: 'get', params, data })
}

export function searchResult(params?: any, data?: any) {
  return request({ url: '/video/search_result', method: 'get', params, data })
}

export function videoTags(params?: any, data?: any) {
  return request({ url: '/video/video_tag_list', method: 'get', params, data })
}
