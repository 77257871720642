<script setup lang="ts">
import BaseMusic from '../BaseMusic.vue'
import { _copy, _formatNumber, _notice, cloneDeep } from '@/utils'
import bus, { EVENT_KEY } from '@/utils/bus'
import { Icon } from '@iconify/vue'
import { useClick } from '@/utils/hooks/useClick'
import { inject } from 'vue'
import axios from 'axios'
import { useBaseStore } from '@/store/pinia'
import config from '@/config'


const props = defineProps({
  isMy: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  item: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const position = inject<any>('position')

const store = useBaseStore()



const emit = defineEmits(['update:item', 'goUserInfo', 'showComments', 'showShare', 'goMusic'])

function _updateItem(props, key, val) {
  const old = cloneDeep(props.item)
  old[key] = val
  emit('update:item', old)
  bus.emit(EVENT_KEY.UPDATE_ITEM, { position: position.value, item: old })
}

function loved() {
  if (!props.item.isLoved) {
    axios
      .post(config.baseUrl + '/user/likeing', {
        authorId: props.item.author.unique_id,
        userId: store.userinfo.userId,
        awemeId: props.item.aweme_id
      })
      .then((res) => {
        if (res.data.code === 200) {
          _updateItem(props, 'statistics', props.item.statistics.digg_count ++)
          _updateItem(props, 'isLoved', !props.item.isLoved)
        }else{
          _notice('点赞失败')
          // window.location.href = '/login'
          //  nav('/login')
        }
      })
    .catch((err) => {
      _notice('点赞失败,请确定是否登录')
      window.location.href = '/login'
      //  nav('/login')
    })
  } else {
    axios
      .post(config.baseUrl + '/user/liked ', {
        authorId: props.item.author.unique_id,
        userId: store.userinfo.userId,
        awemeId: props.item.aweme_id
      })
      .then((res) => {
        if (res.data.code === 200) {
          _updateItem(props, 'statistics', props.item.statistics.digg_count --)
          _updateItem(props, 'isLoved', !props.item.isLoved)
        }else{
          _notice(res.data.message)
          window.location.href = '/login'
          // nav('/login')
        }
      })
      .catch((err) => {
        _notice('取消点赞失败,请确定是否登录')
        window.location.href = '/login'
        // nav('/login')
      })
  }
}

function attention(e) {
  axios
    .post(config.baseUrl + '/user/following', {
      authorId: props.item.author.unique_id,
      userId: store.userinfo.userId
    })
    .then((res) => {
      if (res.data.code === 200) {
        // e.currentTarget.classList.add('attention')
        // setTimeout(() => {
          props.item.author.follow_status = 1
          _updateItem(props, 'isAttention', true)
        // }, 1000)
        _notice(res.data.message)
      }else{
        _notice(res.data.message)
        // nav('/login')
      }
    })
    .catch((err) => {
      _notice("关注失败,请确定是否登录")
      window.location.href = '/login'
      // nav('/login')
    })
}

function collect() {
  if (!props.item.isCollect) {
    axios
      .post(config.baseUrl + '/user/collecting', {
        authorId: props.item.author.unique_id,
        userId: store.userinfo.userId,
        awemeId: props.item.aweme_id
      })
      .then((res) => {
        if (res.data.code === 200) {
          _updateItem(props, 'statistics', props.item.statistics.collect_count ++)
          _updateItem(props, 'isCollect', !props.item.isCollect)
        }else{
          _notice(res.data.message)
        }
      })
      .catch((err) => {
        _notice('收藏失败,请确定是否登录')
        window.location.href = '/login'
        // nav('/login')
      })
  } else {
    axios
      .post(config.baseUrl + '/user/collected', {
        authorId: props.item.author.unique_id,
        userId: store.userinfo.userId,
        awemeId: props.item.aweme_id
      })
      .then((res) => {
        if (res.data.code === 200) {
          _updateItem(props, 'statistics', props.item.statistics.collect_count --)
          _updateItem(props, 'isCollect', !props.item.isCollect)
        }else{
          _notice(res.data.message)
        }
      })
      .catch((err) => {
        _notice('取消收藏失败,请确定是否登录')
        window.location.href = '/login'
        // nav('/login')
      })
  }
}

function share() {
  axios
    .post(config.baseUrl + '/user/share', {
      authorId: props.item.author.unique_id,
      userId: store.userinfo.userId,
      awemeId: props.item.aweme_id
    })
    .then((res) => {
      if (res.data.code === 200) {
        var url = res.data.url
        _copy(url)
        _notice('分享链接已复制到剪切板')
      }else{
        _notice(res.data.message)
        // window.location.href = '/login'
        // nav('/login')
      }
    })
    .catch((err) => {
      _notice('分享失败,请确定是否登录')
      window.location.href = '/login'
      
      // nav('/login')
    })
}

function showComments() {
  bus.emit(EVENT_KEY.OPEN_COMMENTS, props.item.aweme_id)
}

const vClick = useClick()
</script>

<template>
  <div class="toolbar mb1r">
    <div class="avatar-ctn mb2r">
      <img
        class="avatar"
        :src="item.author.avatar_168x168.url_list[0]"
        alt=""
        v-click="() => bus.emit(EVENT_KEY.GO_USERINFO)"
      />
      <transition name="fade">
        <div v-if="(item.author.follow_status === 0 || item.author.follow_status === undefined) && item.author.unique_id !== store.userinfo.userId" v-click="attention" class="options">
          <img class="no" src="../../assets/img/icon/add-light.png" alt="" />
          <img class="yes" src="../../assets/img/icon/ok-red.png" alt="" />
        </div>
      </transition>
    </div>
    <div class="love mb2r" v-click="loved">
      <div>
        <img src="../../assets/img/icon/love.svg" class="love-image" v-if="!item.isLoved" />
        <img src="../../assets/img/icon/loved.svg" class="love-image" v-if="item.isLoved" />
      </div>
      <span>{{ _formatNumber(item.statistics.digg_count) }}</span>
    </div>
    <div class="message mb2r" v-click="showComments">
      <Icon icon="mage:message-dots-round-fill" class="icon" style="color: white" />
      <span>{{ _formatNumber(item.statistics.comment_count) }}</span>
    </div>
    <!--TODO     -->
    <div class="message mb2r" v-click="collect">
      <Icon
        v-if="item.isCollect"
        icon="ic:round-star"
        class="icon"
        style="color: rgb(252, 179, 3)"
      />
      <Icon v-else icon="ic:round-star" class="icon" style="color: white" />
      <span>{{ _formatNumber(item.statistics.collect_count) }}</span>
    </div>
    <div v-if="!props.isMy" class="share mb2r" v-click="share">
      <img src="../../assets/img/icon/share-white-full.png" alt="" class="share-image" />
      <span>{{ _formatNumber(item.statistics.share_count) }}</span>
    </div>
    <div v-else class="share mb2r" v-click="share">
      <img src="../../assets/img/icon/menu-white.png" alt="" class="share-image" />
    </div>
    <!--    <BaseMusic-->
    <!--        :cover="item.music.cover"-->
    <!--        v-click="$router.push('/home/music')"-->
    <!--    /> -->
    <BaseMusic />
  </div>
</template>

<style scoped lang="less">
.toolbar {
  //width: 40px;
  position: absolute;
  bottom: 0;
  right: 10rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatar-ctn {
    position: relative;

    @w: 45rem;

    .avatar {
      width: @w;
      height: @w;
      border: 3rem solid white;
      border-radius: 50%;
    }

    .options {
      position: absolute;
      border-radius: 50%;
      margin: auto;
      left: 0;
      right: 0;
      bottom: -5px;
      background: red;
      //background: black;
      width: 18rem;
      height: 18rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 1s;

      img {
        position: absolute;
        width: 14rem;
        height: 14rem;
        transition: all 1s;
      }

      .yes {
        opacity: 0;
        transform: rotate(-180deg);
      }

      &.attention {
        background: white;

        .no {
          opacity: 0;
          transform: rotate(180deg);
        }

        .yes {
          opacity: 1;
          transform: rotate(0deg);
        }
      }
    }
  }

  .love,
  .message,
  .share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @width: 35rem;

    img {
      width: @width;
      height: @width;
    }

    span {
      font-size: 12rem;
    }
  }

  .icon {
    font-size: 40rem;
  }

  .loved {
    background: red;
  }
}
</style>
